
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import moment from "moment";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import ConvertPoint from "./ConvertPoint.vue";

interface IAlHistory {
  agent: string;
  agent_id: string;
  amount: number;
  createdAt: string;
  notes: string;
  type: string;
}

interface DateRange {
  start: string;
  end: string;
}

export default defineComponent({
  name: "AlPointHistory",
  components: {
    Datatable,
    DateRangePicker,
    ConvertPoint,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    const tableHeader = ref([
      {
        name: t("Deposit.Date"),
        key: "createdAt",
        text: true,
      },
      {
        name: t("Deposit.Type"),
        key: "type",
        customslot: true,
      },
      {
        name: t("AlHistory.Agent"),
        key: "subagent",
        text: true,
      },
      {
        name: t("Point.Amount"),
        key: "amount",
        customslot: true,
      },
      {
        name: t("AlHistory.Memo"),
        key: "notes",
        text: true,
      },
    ]);

    const search = ref({
      type: "ALL",
      agent: "ALL",
    });

    const types = [
      { value: "ALL", label: t("MoneyTransfer.All") },
      { value: "CONVERT", label: t("Point.Transfer") },
      { value: "DIRECT_POINTS", label: t("Point.Savings") },
      { value: "SUB_POINTS", label: t("Point.SubSavings") },
    ];

    /**
     * Daterange Filter
     *
     */
    let daterange = {
      start: moment().startOf("month").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };

    const setSelectedDate = (date: DateRange) => {
      daterange = date;
      getAlPointHistories();
    };

    const tableData = ref<Array<IAlHistory>>([]);
    const totalUsage = ref<number>(0);

    /**
     * Get Points History
     *
     */
    const getAlPointHistories = async () => {
      const results = await ApiService.get(
        `/al/points?type=${search.value.type}&start=${daterange.start}&end=${daterange.end}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
      if (results.length > 0)
        totalUsage.value = results
          .map((item) => Number(item.amount))
          .reduce((prev, next) => Number(prev) + Number(next));
    };
    /**
     * Set Selected History
     *
     */
    const selectedHistory = ref("");
    const onSelectHistory = (historyId: string) =>
      (selectedHistory.value = historyId);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.Point"), [t("Menu.AlManage")]);
    });
    return {
      tableData,
      tableHeader,
      types,
      search,
      totalUsage,
      getAlPointHistories,
      setSelectedDate,
      selectedHistory,
      onSelectHistory,
    };
  },
});
